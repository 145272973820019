import React, { ReactElement, useState } from "react";
import { Collapse, Empty, Space, Tag } from "antd";
import dayjs from "dayjs";
import {
  CaretRightOutlined,
  CloseCircleOutlined,
  ExclamationOutlined,
  InfoCircleOutlined,
  LockOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { useTranslation, Trans } from 'react-i18next';

import {
  EnumMetaProps,
  SearchConditions,
  SearchPanelProps,
} from "@props/RecordProps";
import {
  getAppliedSearchConditionsForDomain,
  removeSearchConditions,
  setSearchConditions,
  removeObsoleteConditions
} from "@kernel/ServerSideSearcher";
import {
  MatcherDisplay,
  MultipleModeMatcher,
  NoValueModeMatcher,
  SingleModeMatcher
} from "@kernel/ServerSideSearcherConfig";
import { DateFormat, DateTimeFormat, PrimaryColor } from '@config/base';
import { stopPropagationAndPreventDefault } from '@utils/ObjectUtils';
import { BooleanCell } from "../../form/cells";
import {
  isDateTimeType,
  isDateType,
  isDynamicSelectColumn,
  isEnumColumn,
  isObjectType
} from "@utils/ColumnsUtils";
import { PredefinedFilters } from "../";
import SaveDynamicFilterPopover from "../../components/filters/SaveDynamicFilterPopover";

const { Panel } = Collapse;

export const SearchConditionDisplayPanel = (props: SearchPanelProps): ReactElement => {
  const {
    domainName, columns, enumValues, objectValues, fetchDataCallback, numOfRow, zIndex
  } = props;

  const { t } = useTranslation();

  // 如果该方法返回 undefined,
  // 则 apply 系统定义的 isDefault 为 true 的 DynamicFilter
  const conditionSaved = getAppliedSearchConditionsForDomain(domainName);

  const { changed, conditions } = (conditionSaved === undefined) ?
    { changed: false, conditions: undefined } : removeObsoleteConditions(conditionSaved, columns);

  if (changed) {
    setSearchConditions(domainName, conditions);
    fetchDataCallback();
  }

  const hasCondition = (Object.keys(conditions ?? {}).length > 0);
  const filterCloseStatus = getFiltersHelpStatus();
  const [panelOpen, setPanelOpen] = useState<boolean>(hasCondition);
  const [filterHelp, setFilterHelp] = useState<boolean>(filterCloseStatus);

  /**
   * Render a search condition on the search panel
   * @param conditions Conditions, with field key as propName, condition information as the value
   */
  function renderConditions(conditions: SearchConditions | undefined): ReactElement {
    if (conditions === undefined) {
      return (<></>);
    }
    const entries = [] as ReactElement[];
    for (const conditionKey in conditions) {
      if (!Object.prototype.hasOwnProperty.call(conditions, conditionKey)) {
        continue;
      }
      const {
        columnKey, matchMode, value, valueLabels,
      } = conditions[conditionKey];
      // ================================= Attention ============================//
      // value != null is not judged here because for isEmpty and isNotEmpty match
      // value is indeed empty
      // ================================== Attention ===========================//
      if (value !== '' && matchMode != null) {
        const isBoolean = (typeof value === "boolean");
        let displayValue: (Array<ReactElement> | ReactElement | string | undefined) = isBoolean ? (
          <BooleanCell value={value as boolean} />) : value?.toString();
        const matchHasNoValueParameter = NoValueModeMatcher.includes(matchMode);
        const emptySpan: ReactElement = (<span className="big-font" />);
        const scqSpan: ReactElement = <span className="scq big-font">&quot;</span>;
        const column = columns?.find(c => c.key === conditionKey);
        const type = column?.type;
        let isEnum = isEnumColumn(type);
        const isDfSelect = isDynamicSelectColumn(type);
        let enumMetaProps: Array<EnumMetaProps> = enumValues[conditionKey];
        if (type == 'enum') {
          isEnum = true;
          enumMetaProps = column?.enumOptions ?? [];
        }
        if (isEnum || isDfSelect) {
          const valueMap = isEnum ? enumMetaProps : null;
          if (MultipleModeMatcher.includes(matchMode)) {
            if (Array.isArray(value)) {
              displayValue = [];
              displayValue = (isEnum) ? value.map((v: string | number, idx: number) => (
                <Tag key={`tag_${idx}`} className="search-condition-field">
                  {valueMap?.find(ev => ev.value === v)?.label}
                </Tag>)) : value.map((v: string | number, idx: number) => (
                  <Tag key={`tag_${idx}`} className="search-condition-field">{v}</Tag>
                )
                );
            }
          } else if (SingleModeMatcher.includes(matchMode) && Array.isArray(value)) {
            if (value?.length > 0) {
              displayValue = (isEnum) ? (<Tag key={`tag_${0}`} className="search-condition-field">
                {valueMap?.find(ev => ev.value === value[0])?.label}
              </Tag>) : value.map(
                (v: string | number, idx: number) => (<Tag key={`tag_${idx}`} className="search-condition-field">{v}</Tag>)
              );
            }
          } else {
            displayValue = (
              <Tag className="search-condition-field">
                {isEnum ? valueMap?.find(ev => ev.value === value)?.label : value?.toString()}
              </Tag>
            );
          }
        } else if (isObjectType(type) || type === 'object') {
          const domainName = column?.elementDomain ?? type;
          if (Array.isArray(value)) {
            displayValue = [];
            displayValue = value.map((v: string | number, idx: number) => {
              const objsOfCurrentType = objectValues[domainName];
              const obj1 = objsOfCurrentType?.find(obj => obj.value.toString() === v);
              let label: string | ReactElement | undefined = obj1?.label;
              if (label == null) {
                label = (valueLabels == null) ? `Id: ${v}(Label not found)` : valueLabels[v];
              }
              if (label == null && (numOfRow != null && numOfRow > 0)) {
                label = (<span style={{ color: "red" }}>
                  <ExclamationOutlined /> {`Id: ${v}(Object not found, no effect)`}</span>
                );
              }
              return (
                <Tag key={`tag_${idx}`}
                  className="search-condition-field"
                >
                  {label}
                </Tag>
              );
            }
            );
          } else {
            displayValue = objectValues[domainName]?.find(ev => ev.value === value)?.label;
          }
        } else if (isDateType(type)) {
          displayValue = dayjs(value as string).format(DateFormat);
        } else if (isDateTimeType(type)) {
          displayValue = dayjs(value as string).format(DateTimeFormat);
        }
        const valueDisplay = matchHasNoValueParameter ? emptySpan : (
          <span>
            {value != null && !isBoolean && (<span>{scqSpan}{displayValue}{scqSpan}</span>)}
            {value != null && isBoolean && (<span>{displayValue}{emptySpan}</span>)}
          </span>
        );
        entries.push(
          <span className="search-condition-container" key={`${conditionKey}_sc`}>
            <a
              href="/#"
              title={t("Click to remove this filter condition")}
              onClick={(e) => {
                stopPropagationAndPreventDefault(e);
                removeSearchConditionByKey(domainName, conditionKey);
              }}
              className="search-condition-button-delete"
            >
              <CloseCircleOutlined className="close-icon" />
            </a>
            <span className="search-condition-body">
              <Tag className="search-condition-field">
                {/* eslint-disable-next-line react/prop-types */}
                {columns.find(c => c.key === columnKey)?.title}
              </Tag>
              <Tag color={PrimaryColor} className="search-matcher-field">
                <CaretRightOutlined /> {t(`matchMode:${MatcherDisplay[matchMode]}`)}
              </Tag>
              {valueDisplay}
            </span>
          </span>
        );
      }
    }
    return (<div className="search-condition-body">{entries}</div>);
  }

  function removeSearchConditionByKey(domainName: string, conditionKey: string): void {
    removeSearchConditions(domainName, conditionKey);
    fetchDataCallback();
  }

  const emptyImage = (
    <span className="filter-title-info" >
      <Space>
        <InfoCircleOutlined />
        <span style={{minWidth: "240px", display: "inline-block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
          <Trans>Please click <SearchOutlined /> icon after each column title to set filter</Trans>
        </span>
        <CloseCircleOutlined
          title={t("Click to hide this help message")}
          onClick={(event: React.MouseEvent<unknown>) => {
            stopPropagationAndPreventDefault(event);
            setFiltersHelpStatus();
          }} />
      </Space>
    </span>
  );
  const innerElement = hasCondition ? (renderConditions(conditions)) : (<Empty
    description={<Trans>Please click <SearchOutlined /> icon after each column title to set filter</Trans>}
    className="search-conditions-empty-panel"
    image={Empty.PRESENTED_IMAGE_SIMPLE}
  />);
  const activeKey = panelOpen ? ['sc-panel'] : hasCondition ? ['sc-panel'] : [];
  function getFiltersHelpStatus(): boolean {
    const val = window.localStorage.getItem("FiltersHelp");
    return !(val != null && val !== "undefined" && val !== "null");
  }

  function setFiltersHelpStatus(): void {
    window.localStorage.setItem("FiltersHelp", "true");
    setFilterHelp(!filterHelp);
  }

  return (<Collapse
    bordered={false}
    activeKey={activeKey}
    defaultActiveKey={activeKey}
    expandIcon={({ isActive }) => {
      if (isActive && hasCondition) {
        return (<LockOutlined title={t("FilterPanelLocked")} />);
      } else if (isActive) {
        return (
          <CaretRightOutlined
            title={t("HideSearchPanel")}
            onClick={() => setPanelOpen(!panelOpen)}
            rotate={90}
          />
        );
      }
      return (<CaretRightOutlined
        title={t("ToggleSearchPanel")}
        onClick={() => setPanelOpen(!panelOpen)}
      />);
    }}
    className={activeKey.length > 0 ? "site-collapse-custom-collapse" : ""}
  >
    <Panel
      header={
        <>
          <span
            title={t("ToggleSearchPanel")}
            onClick={() => setPanelOpen(!panelOpen)}
            style={{ display: "inline-flex", width: "40%" }}
          >
            {
              <span style={{ cursor: 'pointer', minWidth: "60px" }}>{t('Filters')}</span>
            }
            {hasCondition ? (<SaveDynamicFilterPopover
              domainName={domainName}
              zIndex={zIndex + 1}
              updateCallback={fetchDataCallback}
            />) : <span>{filterCloseStatus ? emptyImage : ""}</span>}
          </span>
          <span className="search-panel-predefined-filters">
            <PredefinedFilters
              domainName={domainName}
              fetchDataCallback={fetchDataCallback}
              columns={columns}
              displayStyle="block"
              zIndex={zIndex}
            />
          </span>
        </>
      }
      key="sc-panel"
      className="site-collapse-custom-panel"
    >
      {innerElement}
    </Panel>
  </Collapse>);
};

export default SearchConditionDisplayPanel;
